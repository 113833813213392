import { LinearProgress } from "@mui/material";
import { ApplicantWrapperStyled } from "Modules/Core/Applicants/ApplicantProfile/Applicant.styled";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { getApplicantComments } from "store/slices/employer/applicants/applicant-comments-slice";
import { getApplicantDetails, getFullFingerPrintImage, resetApplicantProfile } from "store/slices/employer/applicants/applicant-profile-slice";
import { fetchApplicantSummary } from "store/slices/employer/applicants/applicants-list-slice";

export const ApplicantWrapper = () => {
    const { jobId, applicantId } = useParams();
    const dispatch = useAppDispatch();
    const { getApplicantDetailsStatus, applicantDetails, fullFingerPrintImage } = useAppSelector((state) => state.employer.applicants.applicantProfile);

    useEffect(() => {
        if (jobId && applicantId) {
            dispatch(getApplicantDetails({ applicationId: parseInt(applicantId), jobId: parseInt(jobId) }));
        }
    }, [applicantId, dispatch, jobId]);

    useEffect(() => {
        if (applicantId) {
            dispatch(fetchApplicantSummary({ candidateEmployerJobId: parseInt(applicantId), jobId: parseInt(jobId ?? '') }));
            dispatch(getApplicantComments({ applicantId: parseInt(applicantId) }));
        }
    }, [applicantId, dispatch, jobId]);

    useEffect(() => {
        if (applicantDetails) {
            if (!applicantDetails.atsPurchased && !fullFingerPrintImage) {
                dispatch(getFullFingerPrintImage({ applicantId: applicantDetails.candidateEmployerJobId }));
            }
        }
    }, [applicantDetails, dispatch, fullFingerPrintImage]);

    // Reset on component unmount.
    useEffect(() => { return () => { dispatch(resetApplicantProfile()) } }, [dispatch]);

    return (<>
        <ApplicantWrapperStyled>
            {
                getApplicantDetailsStatus === 'pending' ? <LinearProgress /> :
                    <>
                        <Outlet />
                    </>
            }
        </ApplicantWrapperStyled>
    </>);
};