import { Card, CardProps, Dialog, FormControlLabel, styled } from "@mui/material";
import {
    ShBorderRadius,
    ShOnHover
} from "shared/SharedStyles/styleConstants";


export const InterviewDialogStyled = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        '& .MuiDialog-paper': {
            minWidth: '320px'
        },
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDialog-paper': {
            minWidth: '800px'
        },
    },
    [theme.breakpoints.up('lg')]: {
        '& .MuiDialog-paper': {
            minWidth: '900px'
        },
    },
    [theme.breakpoints.up('xl')]: {
        '& .MuiDialog-paper': {
            minWidth: '600px'   
        },
    },
    '& .MuiDialogTitle-root': {
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
        '& .MuiButtonBase-root': {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1)
        }
    },
    '& .MuiDialog-paperFullScreen': {
        '& .MuiIconButton-root': {
            right: 0,
            top: 0
        }
    }
}));

interface IInterviewCardStyled extends CardProps {
    borderRadius?: string,
    onlyBottomBorder?: boolean
}

const OnlyBottomBorder = {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none'
}

export const InterviewCardStyled = styled((props: IInterviewCardStyled) => <Card {...props} />, {
    shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "onlyBottomBorder"
})(({ theme, borderRadius = ShBorderRadius, onlyBottomBorder }) => ({
    // [theme.breakpoints.up('sm')]: {
    //     '& .action-items': {
    //         opacity: 1
    //     }
    // },
    // [theme.breakpoints.up('md')]: {
    //     '& .action-items': {
    //         opacity: 0,
    //         transition: 'opacity 0.3s linear'
    //     },
    //     '&:hover': {
    //         '& .action-items': {
    //             opacity: 1
    //         }
    //     }
    // },
    // [theme.breakpoints.up('lg')]: {
    //     '& .action-items': {
    //         opacity: 0,
    //         transition: 'opacity 0.3s linear'
    //     },
    //     '&:hover': {
    //         '& .action-items': {
    //             opacity: 1
    //         }
    //     }
    // },
    // [theme.breakpoints.up('xl')]: {
    //     '& .action-items': {
    //         opacity: 0,
    //         transition: 'opacity 0.3s linear'
    //     },
    //     '&:hover': {
    //         '& .action-items': {
    //             opacity: 1
    //         }
    //     }
    // },
    '& .MuiCardContent-root': {
        paddingBottom: theme.spacing(1)
    },
    '& .MuiCardActions-root': {
        paddingTop: 0
    },
    boxShadow: 'none',
    borderRadius: borderRadius,
    ...(ShOnHover(true, theme, { borderRadius: ShBorderRadius })),
    ...(onlyBottomBorder ? OnlyBottomBorder : {})
}));

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 6,
            overflow: 'auto',
        },
    },
};



export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiTypography-root': {
        padding: theme.spacing(1, 2),
        borderRadius: '20px',
        border: `1px solid ${theme.palette.grey[400]}`,
        cursor: 'pointer',
        transition: 'border-color 0.3s, background-color 0.3s',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    '& .Mui-checked': {
        '& .MuiTypography-root': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
    },
    '&:focus': {
        outline: `2px solid ${theme.palette.primary.main}`,
        outlineOffset: '2px',
    },
}));
