import CommentIcon from '@mui/icons-material/Comment';
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IApplicantComment, IApplicantCommentComponent } from 'Modules/Core/Applicants/ApplicantsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { MouseEvent, useEffect, useState } from 'react';
import { ApiState } from 'shared/SharedModels';
import { ShAlert, ShDialog } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn, ShResizableTextField } from 'shared/SharedStyles/ShInputs';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { getApplicantComments, postApplicantComments, resetCommentsState, updateApplicantComments } from 'store/slices/employer/applicants/applicant-comments-slice';

export const ApplicantComment = ({ applicantId, label, isAddition }: IApplicantCommentComponent) => {

    const dispatch = useAppDispatch();
    const { comments, getCommentsStatus, getCommentsResponse, postCommentsResponse, postCommentsStatus,
        updateCommentsResponse, updateCommentsStatus } = useAppSelector((state) => state.employer.applicants.applicantComments);
    const { accountId } = useAppSelector((state) => state.auth.auth);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [comment, setComment] = useState<IApplicantComment>({ commentable_id: applicantId, user_id: accountId ?? 0, body: '' });

    const openDialog = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        dispatch(resetCommentsState());
        setIsDialogOpen(true);
        e.stopPropagation();
        if (!isAddition) {
            dispatch(getApplicantComments({ applicantId: applicantId }));
        }
    };

    // Set comment by user if any.
    useEffect(() => {
        if (getCommentsStatus === 'success' && comments?.length > 0) {
            const commentByUser = comments?.find(c => c.user_id === accountId);
            if (commentByUser) {
                setComment({ ...commentByUser });
            }
        } else {
            setComment({ commentable_id: applicantId, user_id: accountId ?? 0, body: '' });
        }
    }, [applicantId, comments, getCommentsStatus, accountId]);

    const closeDialog = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        setIsDialogOpen(false);
        e.stopPropagation();
        setComment({ commentable_id: applicantId, user_id: accountId ?? 0, body: '' });
    };

    const addComment = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        if ((postCommentsStatus === 'success' || updateCommentsStatus === 'success')) {
            closeDialog(e);
            dispatch(getApplicantComments({ applicantId: applicantId }));
        } else {
            if (comment?.id) {
                dispatch(updateApplicantComments({ applicantId: applicantId, commentId: comment.id, payload: { body: comment.body ?? '' } }))
            } else {
                dispatch(postApplicantComments({ applicantId: applicantId, payload: { body: comment?.body ?? '' } }));
            }
        }
    };

    const showCommentAlerts = (apiState: ApiState, msg: string) => {
        switch (apiState) {
            case 'pending':
                return (<><ShAlert severity="info">Saving...</ShAlert></>);
            case 'success':
                return (<>
                    <ShAlert severity="success">
                        <Typography fontWeight={600}>{msg ?? DefaultAPIErrorMsg}</Typography>
                    </ShAlert>
                </>);
            case 'failed':
                return (<><ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert></>);
            default:
                break;
        }
    };

    const showFailureAlerts = (apiState: ApiState, msg: string) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    return (<>
        <Tooltip title={`${label ? label : 'Comments'}`} onClick={e => e.stopPropagation()}>
            {label ? <ShButton variant='outlined' disableElevation onClick={(e) => { openDialog(e) }}>
                {label}
            </ShButton> :
                <IconButton className='more-btn' size='small' aria-label='More options' onClick={(e) => { openDialog(e) }}>
                    <CommentIcon fontSize='small' />
                </IconButton>
            }
        </Tooltip>
        <ShDialog open={isDialogOpen} onClose={closeDialog} onClick={e => e.stopPropagation()}>
            <DialogTitle textAlign='center'>Comments</DialogTitle>
            {getCommentsStatus === 'pending' ?
                <>
                    <Stack justifyContent='center' alignItems='center'>
                        <CircularProgress />
                    </Stack>
                </> : <>
                    <DialogContent dividers>
                        {showFailureAlerts(getCommentsStatus, getCommentsResponse)}
                        <Box marginBottom={2} width='100%'>
                            {showCommentAlerts(postCommentsStatus, postCommentsResponse)}
                            {showCommentAlerts(updateCommentsStatus, updateCommentsResponse)}
                        </Box>
                        {comments?.length > 0 ? comments.map(cmnt => (
                            <Stack key={cmnt.id} flexDirection='row' justifyContent='space-evenly' alignItems='center' columnGap={1}>
                                {applicantId === cmnt.commentable_id ? <ShResizableTextField multiline rows={5} maxRows={10}
                                    onClick={e => e.stopPropagation()} autoFocus margin="dense" id="comment" label="Comment" fullWidth
                                    variant="outlined" value={comment.body} onChange={e => setComment({ ...comment, body: e.target.value })} /> :
                                    <>
                                        <Typography>{cmnt.body}</Typography>
                                    </>}
                                <Typography variant='caption' whiteSpace='nowrap'>By: {cmnt.user_name}</Typography>
                            </Stack>
                        )) : <>
                            <ShResizableTextField onClick={e => e.stopPropagation()} multiline rows={3} maxRows={10}
                                autoFocus margin="dense" id="comment" label="Comment" fullWidth
                                variant="outlined" value={comment.body} onChange={e => setComment({ ...comment, body: e.target.value })} />
                        </>}
                    </DialogContent>
                    <DialogActions>
                        <ShButton size='small' variant='outlined' onClick={(e) => closeDialog(e)}>
                            Cancel</ShButton>
                        <ShGreenBtn size='small' disabled={!comment?.body || postCommentsStatus === 'pending' ||
                            updateCommentsStatus === 'pending'} onClick={(e) => addComment(e)}>
                            {(postCommentsStatus === 'pending' || updateCommentsStatus === 'pending') ? 'Saving...' :
                                (postCommentsStatus === 'success' || updateCommentsStatus === 'success') ? 'Close' :
                                    comment?.id ? 'Save Comment' : 'Add Comment'}
                        </ShGreenBtn>
                    </DialogActions>
                </>}
        </ShDialog>
    </>);
};