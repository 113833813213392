export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const EmployerApiEndpoints = {
    ACCOUNT_LOGIN: 'v1/users/login',
    REFRESh_TOKEN: 'auth/refreshtoken',
    ACTIVE_JOBS: 'v1/jobs/active',
    JOBS: 'v1/jobs',
    CREATE_TEMPLATE_JOB: 'v1/employers/jobs/template-jobs/create',
    UPDATE_TEMPLATE_JOB: 'v1/employers/jobs/template-jobs/update',
    USE_TEMPLATE_JOB: 'v1/employers/jobs/template-jobs/use',
    AREA_INFORMATION: 'v1/area-information',
    JOBS_LIST: 'v1/jobs/states',
    TEMPLATE_JOBS_LIST: 'v1/employers/jobs/template',
    DRAFT_JOBS: 'v1/jobs/draft',
    EXPIRED_JOBS: 'v1/jobs/expired',
    STATE_SUMMARY: 'v1/jobs/state/summary',
    JOB_SUMMARY: 'v1/jobs/job/summary',
    JOB_DETAILS_BY_CODE: 'v1/jobs/code',
    AI_JOB_DESCRIPTION: 'v1/ai/job_description',
    AI_INTERVIEW_QUESTIONS: 'v1/ai/interview_questions/',
    JOBS_SUMMARY: 'v1/jobs/summary',
    APPLICANTS_JOBS: 'v1/applicants/jobs',
    EXPORT_JOBS: 'v1/jobs/list/export',
    INVITE_APPLICANTS: 'v1/jobs/job/invite/applicants',
    APPLICANT_SUMMARY: 'v1/applicants/applicant/summary',
    APPLICANTS: 'v1/applicants',
    EMPLOYER_INDUSTRIES: 'v1/employers/industries',
    EMPLOYER_COUNTRIES: 'v1/employers/countries',
    EMPLOYER_JOBS: 'v1/employerjobs',
    SOURCES: 'v1/signup_sources',
    OAUTH_LOGIN: 'v1/oauth_login',
    OAUTH_SIGNUP_CANDIDATE: 'v1/oauth_signup/candidate',
    EMPLOYERS: 'v1/employers',
    EMPLOYER_POSTED_JOBS: 'v1/jobs/states/posted',
    EMPLOYER_ACCOUNTS: 'v1/employer_accounts',
    FORGOT_PASSWORD: 'v1/users/forgot-password',
    RESET_PASSWORD: 'v1/users/user/reset-password',
    DEVICE_AUTH: 'v1/users/validate/security/authentication',
    SEND_SECURITY_CODE: 'v1/users/security-code',
    VALIDATE_SECURITY_CODE: 'v1/users/validate/security-code',
    GET_ACC_INFO: 'v1/users/account/me',
    USERS_USER: 'v1/users/user',
    GET_COMPANY_INFO: 'v1/employers/employer/account/info',
    GET_COMPANY_LOGO: 'v1/employers/logo',
    ADD_USER: 'v1/users/user/add',
    ROLES: 'v1/users/user/roles',
    GET_AUTH_QS: 'v1/users/auth/questions',
    SECURITY_AUTH: 'v1/users/security/authentication',
    RESET_AUTH_ANSWER: 'v1/users/forgot/security/authentication',
    CHANGE_AUTH_QS: 'v1/users/change/security/authentication',
    CHANGE_USER_PASSWORD: 'v1/users/user/change-password',
    UPDATE_EMPLOYERS_LOGO: 'v1/employers/logo',
    JOB_INDUSTRIES: 'v1/jobs/job/industries',
    JOB_STATES: 'v1/jobs/job/states',
    JOB: 'v1/jobs/job',
    INDUSTRIES: 'v1/industries',
    JOBS_EMPLOYER: 'v1/jobs/employer',
    JOBS_TITLES: 'v1/jobTitles',
    ALL_BUNDLES: 'v1/employers/me/bundles',
    INVENTORY_BUNDLES: 'v1/employers/me/inventory',
    IS_ACCOUNT_EXISTS: 'v1/users/user/email/exist',
    INFO_BY_RESUME: 'v1/candidates/sign_in/resume',
    SURVEY_QUESTIONS: 'v1/applicants/accounts/survey',
    ASSESSMENT_META: 'v1/candidates/questions/meta/',
    SURVEY_ANSWERS: 'v1/applicants/survey/answers',
    INTERVIEWS: 'v1/interviews',
    INTERVIEWS_SUMMARY: 'v1/interviews/summary',
    STOP_HIRING: 'v1/jobs',
    CANDIDATE_SIGN_UP: "v1/candidates/signup",
    CANDIDATE_NOTIFICATIONS: "v1/job_notifications",
    JOB_APPLY: "v1/candidates/jobs/apply",
    JOB_REQUIREMENTS: "v1/applicants/applicant",
    CANDIDATE_DETAILS: "v1/candidates/self/applications",
    CANDIDATES: "v1/candidates",
    JOB_APPLY_COMPLETE: "v1/applications",
    CANDIDATE_JOB_APPLICATION_HISTORY: "v1/applicants/applicant/history",
    APPLICANT_INCOMPLETE: "v1/applicants/incomplete",
    UPDATE_APPLICANT_PROFILE: "v1/users/user",
    UPDATE_APPLICANT_EMAIL: "v1/users/user/email",
    UPDATE_APPLICANT_PASSWORD: "v1/users/user/change-password",
    ZOOM_MEETS: "v1/zoom_meetings",
    CAREER_REPORT: "v1/candidates/careerreport",
    STATES_BY_COUNTRY: "v1/employers/states",
    AI_SALARY_PREDICTION: 'v1/ai/job_salary/',
    CANDIDATE_CURRENT_JOBS: 'v1/job_comparisions'
};

export const AdminApiEndpoints = {
    ADMIN_EMPLOYER_JOBS: 'v1/admin/employerjobs',
    ADMIN_NOTIFICATIONS: 'v1/admin/notifications/',
    ADMIN_UNREAD_NOTIFICATIONS: 'v1/admin/notifications/unread',
    PRICING_GROUPS: 'v1/admin/employers/pricing_groups',
    ADMIN_EMPLOYERS: 'v1/admin/employers',
    ADMIN_JOB_REVIEWS: 'v1/admin/jobReviews',
    ADMIN_ACCOUNTS_CANDIDATE: 'v1/admin/accounts/candidate',
    ADMIN_EMP_SEARCH: 'v1/admin/quickSearch/employer',
    ADMIN_GENERATE_FEEDS: '/',
    ADMIN_HARD_RESET: 'v1/users/user/hard-reset'
}

export const CommonApiEndpoints = {
    TIMEZONES: 'v1/timezones'
}