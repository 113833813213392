import { ICreateZoomMeetPayload, TInterviewDialogUsedIn } from "Modules/Core/Interviews/InterviewsModel";
import { IJob } from "Modules/Core/JobsTs/JobsModel";
import { Dispatch, SetStateAction } from "react";
import { ApiState, ICareerSeekerNavLinks, ILabelValueBase, IPaginationParams, ISortBase, ISortParamsBase, ISummaryComponentBase, LayoutTypes, SortOrder } from "shared/SharedModels";

export type ApplicantsSortCols = 'first_name' | 'created_at' | 'rating';
export type SkillVal = 'THREE' | 'TWO' | 'ONE';
export type FilterType = 'stages' | 'fit' | 'keywords' | 'rating';
export type TInterviewStage = 'new' | 'second_interview' | 'hired' | 'auto_rejected' | 'offer' |
    'phone_screening' | 'rejected' | 'interview' | 'not_qualified' | 'reviewed';

export enum JobStages {
    Interview = 'INTERVIEW',
    Hired = 'HIRED',
    Rejected = 'REJECTED',
    Phone_Screening = 'PHONE_SCREENING',
    Second_Interview = 'SECOND_INTERVIEW',
    Offer = 'OFFER',
};

/* Component Interfaces */
export interface IFilterComponent {
    applyFilters: (filters: IApplicantsFilter) => void,
    selectedFilters: IApplicantsFilter,
};

export interface IApplicantsComponentBase extends IChangeStage {
    applicantsList: IApplicant[],
    applicantsSummary: IApplicantsSummary,
    selectedApplicants: number[],
    selectApplicant: (applicantId: number) => void,
    getApplicantSummary: (candidateEmployerJobId: number) => void,
    goToApplicantProfile: (applicant: IApplicant) => void,
    downloadHiringGuide: (applicant: IApplicant) => void;
    isAtsPurchased?: boolean
};

export interface IApplicantsToolBar extends IFilterComponent, ISortBase<ApplicantsSortCols> {
    layoutType: LayoutTypes,
    setLayoutType: Dispatch<SetStateAction<LayoutTypes>>,
    removeFilter: (fltrType: FilterType, val: string) => void,
    isSmScreen: boolean,
    searchKey: string,
    searchInApplicants: (searchKey: string) => void
};

export interface IApplicantsFooter {
    paginationParams: IPaginationParams,
    getApplicants: (pageNo?: number, pageSize?: number, filters?: IApplicantsFilter, sortParams?: ISortParamsBase<ApplicantsSortCols>) => void,
    isSmScreen: boolean,
    selectedApplicants: number[],
    jobId: number
};

export interface IApplicantsTable extends IApplicantsComponentBase, IChangeStage, ISortBase<ApplicantsSortCols> { };

export interface IChangeStage {
    changeStage: (stage: Lowercase<TInterviewStage>, candidateId: number, sendEmail: boolean) => void
};

export interface IApplicantRowModel extends IChangeStage {
    applicant: IApplicant,
    selectedApplicants: number[],
    selectApplicant: (applicantId: number) => void,
    isAtsPurchased?: boolean,
    indx: number,
    applicantsSummary: IApplicantsSummary,
    getApplicantSummary: (candidateEmployerJobId: number) => void,
    goToApplicantProfile: (applicant: IApplicant) => void,
    downloadHiringGuide: (applicant: IApplicant) => void;
};

/* Data models */
export interface IApplicant {
    applicationCompletedAt: string,
    atsPurchased: boolean,
    candidateEmployerJobId: number,
    candidateId: number,
    commentTotal: number,
    distortionCount: number,
    email: string,
    employerJobId: number,
    firstName: string,
    jobStage: TInterviewStage,
    stageModifiedAt: Date,
    lastName: string,
    matchType: string,
    rating: number,
    isExpanded?: boolean,
    changeStateStatus?: ApiState,
    changeStateResponse?: string,
    updateRatingStatus?: ApiState,
    updateRatingResponse?: string,
    fullName?: string
    hasReceivedInterviewEmail?: boolean;
    hasReceivedOfferLetterEmail?: boolean;
    hasReceivedRejectionEmail?: boolean;
};

export interface IApplicantsFilter {
    stages: ILabelValueBase<string, TInterviewStage>[],
    fit: ILabelValueBase[],
    keywords: ILabelValueBase[],
    rating: ILabelValueBase[],
};
export interface IApplicantSummaryParams {
    jobId: number,
    candidateEmployerJobId: number
};

interface IEduQualifications {
    hasRequirement: boolean,
    name: string
};

interface ISkillReq {
    name: string,
    skillRating: SkillVal
};

export interface IComment {
    comment: string,
    commentDate: string,
    commentId: number,
    commentOwner: string
};

export interface IApplicantSummary {
    baseballCardUrl?: string,
    commentSummaries?: IComment[],
    educationQualificationRequirements?: IEduQualifications[],
    hasAppliedBeforeRequirementsChange?: boolean,
    skillRequirements?: ISkillReq[],
    getSummaryStatus?: ApiState,
    getSummaryResponse?: string,
    getFingerprintImageStatus?: ApiState,
    getFingerprintImageResponse?: string
};

export interface IApplicantsSummary {
    [candidateEmployerJobId: number]: IApplicantSummary,
};

/* API interfaces */
export interface IApplicantsApiParams {
    jobId: number,
    pgNo: number,
    pgSize: number,
    sortCol: ApplicantsSortCols,
    sortDir: SortOrder,
    search?: string,
    finalMatches?: string,
    stages?: string,
    keywords?: string,
    ratings?: string
};

export interface IApplicantSummaryResponse {
    data: IApplicantSummary,
    message: string,
    status: {
        message: string,
        statusCode: number,
        timeStamp: string,
        version: string
    }
};

export interface IApplicantsListResponse {
    data: IApplicant[],
    stats: IPaginationParams & { atsPurchased: boolean },
    message: string,
    status: {
        message: string,
        statusCode: number,
        timeStamp: string,
        version: string
    }

};

export interface IApplicantsListState {
    getApplicantsListStatus: ApiState,
    getApplicantsListResponse: string | undefined,
    applicantsList: IApplicant[],
    paginationParams: IPaginationParams,
    isAtsPurchased?: boolean,
    applicantsSummary: IApplicantsSummary,
    applicantSummary?: IApplicantSummary,
    getApplicantSummaryStatus?: ApiState,
    getApplicantSummaryResponse?: string,
    applicantFingerPrintImageUrl?: string,
    getApplicantFingerPrintImageStatus?: ApiState,
    getApplicantFingerPrintImageResponse?: string,
    stageChangeStatus?: ApiState
};

interface IEmployerJobData {
    name: string,
    city: string,
    company_alias: string,
    is_complete: boolean,
    completed_at: string,
}

export interface IJobApplyHistoryData {
    id: number | null,
    candidate_id: number,
    employer_id: number,
    employerjob_id: number,
    final_match: string,
    fingerprint_key: string,
    fingerprint_match: string | null,
    fingerprint_score: string | null,
    submitted_at: string,
    is_archived: boolean,
    is_background_checks: boolean,
    organizational_profile_match: null | string,
    postingsource_id: number,
    processed_fingerprint: boolean,
    rating: null | string,
    referrer: null | string,
    requirement_score: string | null,
    resume_id: number,
    stage: string,
    asked_to_complete_assessment_at: string | null,
    asked_to_complete_assessment_by_id: null | string,
    asked_to_retake_assessment_at: null | string,
    application_completed_at: null | string,
    retook_assessment_at: null | number,
    is_resume_processing: boolean,
    complete_link: null | string,
    retake_link: null | string,
    resume_link: null | string,
    view_resume_link: string,
    sends_alerts: boolean,
    employerjob: IEmployerJobData,
    createdAt: string,
}

export interface IApplicantActionsState {
    zoomMeetCreationStatus: ApiState,
    zoomMeetCreationResponse: string,
    zoomMeetResponse?: IZoomMeetResponse,
    zoomMeetUpdateStatus: ApiState,
    zoomMeetUpdateResponse: string,
    zoomMeetDeleteStatus: ApiState,
    zoomMeetDeleteResponse: string,
    rejectionEmailStatus?: ApiState,
    rejectionEmailResponse?: string,
    downloadingGuideStatus?: ApiState,
    downloadingGuideResponse?: string
};

export interface IZoomMeetAPIPayload {
    payload?: ICreateZoomMeetPayload,
    zoomId?: number
};

export interface IApplicantsListActionsState {
    shareApplicantsStatus: ApiState,
    shareApplicantsResponse?: string,
    downloadAsCSVStatus: ApiState,
    downloadAsCSVResponse?: string,
    downloadAsExcelStatus: ApiState,
    downloadAsExcelResponse?: string,
    inviteApplicantsStatus?: ApiState,
    inviteApplicantsResponse?: string,
    getJobFilterKeywordsStatus?: ApiState,
    getJobFilterKeywordsResponse?: string,
    jobFilterKeywords?: string[]
};

export interface IApplicantCommentsState {
    getCommentsStatus: ApiState,
    getCommentsResponse: string,
    comments: IApplicantComment[],
    postCommentsStatus: ApiState,
    postCommentsResponse: string,
    updateCommentsStatus: ApiState,
    updateCommentsResponse: string,
};

export interface IApplicantStageParams {
    applicantId: number,
    payload: { stage: Lowercase<TInterviewStage>, sendEmail: boolean }
}

export interface IShareToEmailParams {
    toEmail: string,
    employerJobId: number,
    applicantIds: string
};

export interface ICareerSeekerResources {
    resourceItems: ICareerSeekerNavLinks[];
}

export interface IApplicantHistoryItem {
    title: string;
    company: string;
    location: string;
    applied?: string;
    complete: boolean;
}

export interface IApplicantHistoryComponent {
    ApplicationHistoryList: IJobApplyHistoryData[];
}
export interface IApplicantCommentComponent {
    applicantId: number,
    commentId?: number,
    label?: string,
    isAddition?: boolean
};

export interface IApplicantComment {
    id?: number,
    body?: string,
    subject?: string,
    user_id: number,
    user_name?: string,
    commentable_type?: string,
    commentable_id: number,
    created_at?: string,
    updated_at?: string
};

export interface IApplicantCommentPost {
    applicantId: number,
    payload: { body: string }
};

export interface IApplicantCommentUpdate extends IApplicantCommentPost {
    commentId: number
};

export interface IApplicantRatingComponent {
    applicant: IApplicant,
    className?: string,
    usedIn: 'list' | 'profile'
};

export interface IApplicantRatingUpdate {
    applicantId: number,
    payload: { rating: number }
};

export interface IIncompleteApplicant {
    id: number,
    is_archived: boolean,
    employer_id: number,
    candidate_id: number,
    employerjob_id: number,
    asked_to_retake_assessment_at: string,
    asked_to_complete_assessment_at: string,
    asked_to_complete_assessment_by_id: string,
    retook_assessment_at: string,
    fingerprint_score: string,
    requirement_score: string,
    fingerprint_match: string,
    final_match: string,
    type: string,
    callback_when_complete: string,
    callback_when_incomplete: string,
    api_key: string,
    organizational_profile_match: string,
    stage: string,
    fingerprint_key: string,
    processed_fingerprint: true,
    resume_keyword_matches: string,
    application_completed_at: string,
    postingsource_id: number,
    user_agent: string,
    referrer: string,
    ip: string,
    rating: string,
    resume_id: number,
    submitted_at: string,
    is_background_checks: boolean,
    createdAt: string,
    updatedAt: string,
    candidate: {
        id: number,
        first_name: string,
        middle_name: string,
        last_name: string,
        account_id: number,
        account: {
            id: number,
            first_name: string,
            middle_name: string,
            last_name: string,
            email: string,
        }
    },
    employerjob: { id: number },
    resume: { id: number, text: string, }
};

export interface IIncompleteApplicantsState {
    getIncompleteApplicantsListStatus: ApiState,
    getIncompleteApplicantsListResponse?: string,
    incompleteApplicantsList: IIncompleteApplicant[],
    downloadAsCSVStatus?: ApiState
};

export interface ISuggestedQuestions {
    questions_to_ask?: string,
    suggested_responses?: string,
    strong_responses?: string,
    weak_responses?: string,
    questions?: {
        title?: string,
        blurb?: string,
        questions?: {
            [key: string]: {
                head?: string,
                follows?: string[]
            }
        },
        strong_responses?: string[],
        weak_responses?: string[]
    }[]
};

export interface IGetApplicantDetailsParams {
    applicationId: number,
    jobId: number
}

export interface IApplicantProfileState {
    getApplicantDetailsStatus?: ApiState,
    getApplicantDetailsResponse?: string,
    applicantDetails?: IApplicant,
    getFullFingerPrintImageStatus?: ApiState,
    getFullFingerPrintImageResponse?: string,
    fullFingerPrintImage?: string,
    getSuggestedQuestionsStatus?: ApiState,
    getSuggestedQuestionsResponse?: string,
    suggestedQuestions?: ISuggestedQuestions,
    getApplicantResumeStatus?: ApiState,
    getApplicantResumeResponse?: string,
    resumeS3Url?: string,
    updateApplicantProfileApiStatus?: ApiState,
    updateApplicantProfileApiResponse?: string,
    updatePasswordApiStatus?: ApiState,
    updatePasswordApiResponse?: string,
};

export interface IUpdateApplicantProfile {
    first_name: string,
    last_name: string,
    middle_name?: string,
    applicantId?: number
}

export interface IUpdateApplicantPassword {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
};

export interface IZoomMeetResponseData {
    agenda: string
    created_at: string
    duration: number
    encrypted_password: string
    h323_password: string
    host_email: string
    host_id: string
    id: number
    join_url: string
    password: string
    pre_schedule: boolean
    pstn_password: string
    settings: any
    start_time: string
    start_url: string
    status: string
    timezone: string
    topic: string
    type: number
    uuid: string
};

export interface IZoomMeetResponse {
    id: number,
    response: IZoomMeetResponseData
};

export interface IInviteApplicants {
    isInviteApplicantsOpen: boolean
    setIsInviteApplicantsOpen: Dispatch<SetStateAction<boolean>>,
    job: IJob
}

export interface IInviteApplicantsApiParams {
    jobId: number,
    body: IInviteApplicantsPayload
}

export interface IInviteApplicantsPayload {
    to: string,
    subject: string,
    body: string
}

export interface IRejectionEmailDialog {
    isRejectionEmailDialogOpen: boolean,
    setIsRejectionEmailDialogOpen: Dispatch<SetStateAction<{ [key: number]: boolean; } | undefined>>,
    applicant: IApplicant,
    jobId: number
}

export interface IRejectionEmailPayload {
    subject: string,
    to: string,
    cc: string,
    bcc: string,
    data: string,
}

export interface IApplicantSummaryProps extends ISummaryComponentBase<IApplicantSummary> {
    changeStage: (stage: Lowercase<TInterviewStage>, candidateId: number, sendEmail: boolean) => void,
    applicantId: number;
    applicant: IApplicant;
    goToApplicantProfile: (applicant: IApplicant) => void;
    downloadHiringGuide: (applicant: IApplicant) => void;
    isAtsPurchased?: boolean
}

export interface IStagesTogglerProps extends IChangeStage, Partial<HTMLElement> {
    applicant: IApplicant,
    includeOnlyInterviews?: boolean,
    usedIn: TInterviewDialogUsedIn
};