import { Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { DefaultAPIErrorMsg } from "shared/constants";
import { getApplicantResume, resetGetApplicantResume } from "store/slices/employer/applicants/applicant-profile-slice";

export const ApplicantResume = () => {

    const dispatch = useAppDispatch();
    const { applicantId } = useParams();
    const { getApplicantResumeStatus, getApplicantResumeResponse,
        resumeS3Url } = useAppSelector((state) => state.employer.applicants.applicantProfile);

    // get resume
    useEffect(() => {
        if (applicantId) {
            dispatch(getApplicantResume({ applicantId: parseInt(applicantId) }));
        }
        return () => {
            dispatch(resetGetApplicantResume());
        }
    }, [applicantId, dispatch]);

    return (<>
        <Typography variant="h6" gutterBottom>Resume</Typography>
        {getApplicantResumeStatus === 'failed' && applicantId &&
            <>
                <Box marginBottom={2} width='100%'>
                    <ShAlert severity="error" action={
                        <ShButton color="inherit" size="small"
                            onClick={() => dispatch(getApplicantResume({ applicantId: parseInt(applicantId) }))}>
                            Retry
                        </ShButton>
                    } >
                        {getApplicantResumeResponse ?? DefaultAPIErrorMsg}
                    </ShAlert>
                </Box>
            </>}
        {getApplicantResumeStatus === 'pending' ?
            <ShAlert severity="info">Loading Resume...</ShAlert> :
            <>
                {resumeS3Url &&
                    <Box flex={1} display='flex' flexDirection='column'>
                        <iframe title="applicant resume"
                            src={resumeS3Url}
                            width="100%"
                            height="100%"
                        ></iframe>
                    </Box>
                }
            </>}
    </>);
};