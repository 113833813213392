import {
  EmployerDetailsWrapper,
  HardResetPassword,
  ActiveAdministrators,
} from 'Modules/Core/Admin';
import { AdminDashboard } from 'Modules/Core/Admin/AdminDashboard';
import { AdminJobs } from 'Modules/Core/Admin/AdminDashboard/Jobs/AdminJobs';
import JobReview from 'Modules/Core/Admin/AdminDashboard/Jobs/JobReview';
import { AuthV2 } from 'Modules/Core/AuthV2/AuthV2';
import { AdminLayout } from 'Modules/Core/Layouts/AdminLayout/AdminLayout';
import { Route, Routes } from 'react-router-dom';

export const AdminRouter = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthV2 appContext="admin" />}>
          <Route path="/" element={<AdminLayout />}>
            <Route path="" element={<AdminDashboard />} />
            {[
              '/pending-jobs',
              '/approved-jobs',
              '/rejected-jobs',
              '/removed-jobs',
              '/pending-update-jobs',
            ].map((path, key) => (
              <Route key={key} path={path} element={<AdminJobs />} />
            ))}
            <Route path="/job-reviews/:jobid/edit" element={<JobReview />} />
            <Route
              path="/employers/:employerId/edit"
              element={<EmployerDetailsWrapper />}
            />
            <Route path="/accounts">
              <Route path="" element={<ActiveAdministrators />} />
              <Route path="reset_password" element={<HardResetPassword />} />
              <Route path="delete_account" element={<div>Coming Soon</div>} />
              <Route path="security_code" element={<div>Coming Soon</div>} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};
