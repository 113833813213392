import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import { AuthCodeCountdown } from 'Modules/Core/AppLogin/AppLoginConstants';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useCallback, useEffect, useState } from "react";
import { ShAlert, ShDialog } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { loginWithTwoStepAuth } from 'store/slices/auth-v2/auth-v2-slice';
import { sendSecurityCode, validateSecurityCode, verifySecurityAnswer } from "store/slices/auth-v2/two-step-auth-slice";
import { ITwoStepAuthDialogProps } from "./AuthModel";

/**
 * Two step authorization dialog.
 * Closes only when user responds with any answer. 
 * If user clicks away from the dialog then the emitted event will return undefined,  which will block the dialog from closing.
 * */
export const TwoStepAuthDialog = ({ isAuthDialogOpen, onAuthDialogClose, usedIn }: ITwoStepAuthDialogProps) => {

    const dispatch = useAppDispatch();
    const { sendSecurityCodeApiStatus, sendSecurityCodeApiResponse, validateSecurityCodeApiStatus, deviceAuthApiResponse,
        deviceAuthApiStatus, validateSecurityCodeApiResponse, twoStepAuthLoginResponse
    } = useAppSelector(state => state.auth.twoStepAuth);
    const { twoStepAuth, email } = useAppSelector((state) => state.auth.auth);
    const [authAnswer, setAuthAnswer] = useState<string>('');
    const [securityCode, setSecurityCode] = useState<string>('');
    const [showSecurityCodeLogin, setShowSecurityCodeLogin] = useState<boolean>(false);
    const [seconds, setSeconds] = useState<number>(AuthCodeCountdown);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const startTimer = useCallback(() => {
        setSeconds(AuthCodeCountdown);
        const interval = setInterval(() => {
            setSeconds(prevSeconds => (prevSeconds === 0 ? 0 : prevSeconds - 1));
        }, 1000);
        setTimer(interval);
    }, []);

    const resetTimer = useCallback(() => {
        if (timer) {
            clearInterval(timer);
            setTimer(null);
        }
    }, [timer]);

    useEffect(() => {
        if (seconds === 0) {
            resetTimer();
        }
    }, [seconds, resetTimer]);

    const resendSecurityCode = () => {
        resetTimer(); // Reset timer before starting it again
        startTimer(); // Start the timer again
        getSecurityCode();
    };

    const getSecurityCode = useCallback(() => {
        /*
            Toggling between security code and question-answer mode of device verifications.
            When user switches to security code authentication, question-answer is hidden and vice-versa.
        */
        dispatch(sendSecurityCode());
    }, [dispatch]);

    const closeDialog = useCallback((isAuthenticated: boolean) => {
        setAuthAnswer('');
        setSecurityCode('');
        onAuthDialogClose(isAuthenticated);
    }, [onAuthDialogClose]);

    useEffect(() => {
        // 'onAuthDlgClose' will send 'true' to it's parent component if the authentication is successful. 
        if (deviceAuthApiStatus === 'success' || validateSecurityCodeApiStatus === 'success') {
            if (twoStepAuthLoginResponse) {
                dispatch(loginWithTwoStepAuth({ twoStepLoginResponse: twoStepAuthLoginResponse, loginPage: usedIn }));
            }
            closeDialog(true);
        }
    }, [closeDialog, deviceAuthApiStatus, dispatch, twoStepAuthLoginResponse, usedIn, validateSecurityCodeApiStatus]);

    /*
    * show email verification dialog if two step is 'Email code'. OR
    * set sendSecurityCodeApiStatus to true if user clicks Forgot Answer and security code is sent to email.
    */
    useEffect(() => {
        if (twoStepAuth?.emailVerification || sendSecurityCodeApiStatus === 'success') {
            setShowSecurityCodeLogin(true);
            startTimer();
        }
    }, [twoStepAuth?.emailVerification, sendSecurityCodeApiStatus, startTimer]);

    return (<>
        <ShDialog open={isAuthDialogOpen} onClose={() => closeDialog(false)}>
            <DialogTitle id='title' textAlign='center' fontWeight={600}>
                {showSecurityCodeLogin ? 'Enter your security code' : 'Authenticate your Account'}
                <IconButton onClick={() => closeDialog(false)}><ClearIcon /></IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Stack alignItems='center' rowGap={1}>
                    {
                        showSecurityCodeLogin ?
                            // Authentication with security code block.
                            <>
                                {sendSecurityCodeApiStatus === 'failed' ? <>
                                    <ShAlert severity='error'>Something went wrong. Please close this dialog and try again.</ShAlert>
                                </> :
                                    <>
                                        <Typography marginBottom={1} variant='body2'>
                                            {sendSecurityCodeApiStatus === 'pending' ? 'Sending code to email...' :
                                                sendSecurityCodeApiResponse ?? 'Please enter the security code sent to your email'}
                                        </Typography>
                                        <Typography marginBottom={1} color='primary' variant='body1'>{email}</Typography>
                                        <Typography marginBottom={1} color={'gray'} variant='caption'>
                                            Didn't get verification code? Check spam folder.</Typography>
                                        {seconds === 0 ?
                                            <Box marginBottom={1}>
                                                <ShButton onClick={resendSecurityCode} size='small'>Resend the Code</ShButton>
                                            </Box> :
                                            <Typography variant='caption' gutterBottom>
                                                Resend Code in {seconds} seconds.
                                            </Typography>
                                        }
                                        <TextField size="small" id="securityCode" placeholder='Security code' fullWidth
                                            variant="outlined" value={securityCode}
                                            onChange={(e) => setSecurityCode(e.target.value)} />
                                        <Stack rowGap={1}>
                                            {/* Display error messages from the validationAPI*/}
                                            <Typography variant='caption' display='flex' alignItems='center'>
                                                {validateSecurityCodeApiResponse &&
                                                    <><ErrorIcon color='error' />{validateSecurityCodeApiResponse}</>}</Typography>
                                            {/* Link to question-answer block */}
                                            {!twoStepAuth?.emailVerification &&
                                                <ShMuiLink href='#' onClick={() => setShowSecurityCodeLogin(false)} gutterBottom>
                                                    <Typography variant='body2'>
                                                        Answer security question
                                                    </Typography>
                                                </ShMuiLink>
                                            }
                                        </Stack>
                                        <ShGreenBtn variant='outlined' disableElevation
                                            disabled={securityCode?.length === 0 || validateSecurityCodeApiStatus === 'pending'}
                                            onClick={() => dispatch(validateSecurityCode({ payload: { security_code: securityCode } }))}>
                                            {validateSecurityCodeApiStatus === 'pending' ? 'Validating...' : 'Submit'}</ShGreenBtn>
                                    </>}
                            </> :
                            // Authentication with question and answer block.
                            <>
                                {/* Two step auth icon */}
                                <Box className='auth-icon'></Box>
                                <Typography marginBottom={1} color='primary'>Confirm that it’s you</Typography>
                                <Typography marginBottom={2}>Answer the below security question</Typography>
                                <Typography marginBottom={1.5}>
                                    {twoStepAuth?.twoStepAuthQuestion?.question + '' + (twoStepAuth?.twoStepAuthQuestion?.question && ' ?')}
                                </Typography>
                                <TextField size="small" id="auth_answer" placeholder='Answer' fullWidth
                                    variant="outlined" value={authAnswer}
                                    onChange={(e) => setAuthAnswer(e.target.value)} />
                                <Stack direction='row' columnGap={1} alignItems='center'>
                                    {deviceAuthApiStatus !== 'pending' &&
                                        <>
                                            {/* Display error messages from the validationAPI*/}
                                            <Typography variant='caption' display='flex' alignItems='center' columnGap={0.5}>
                                                {deviceAuthApiResponse && <><ErrorIcon />Your answer is incorrect</>}</Typography>
                                        </>}
                                    <ShMuiLink href='#' onClick={getSecurityCode} gutterBottom>Forgot Answer?</ShMuiLink>
                                </Stack>
                                <ShGreenBtn variant='contained' disableElevation
                                    disabled={authAnswer?.length === 0 || deviceAuthApiStatus === 'pending'}
                                    onClick={() => dispatch(verifySecurityAnswer({
                                        question_id: twoStepAuth?.twoStepAuthQuestion?.question_id ?? 0,
                                        auth_answer: authAnswer
                                    }))}>
                                    {deviceAuthApiStatus === 'pending' ? 'Validating...' : 'Authorize'}</ShGreenBtn>
                            </>
                    }
                </Stack>
            </DialogContent>
        </ShDialog>
    </>);
}