import ClearIcon from '@mui/icons-material/Clear';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import {
    Box,
    Chip,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    ToggleButton,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { InterviewPreviewDialog } from 'Modules/Core/Interviews/InterviewPreviewDialog';
import { InterviewDialogStyled, MenuProps } from "Modules/Core/Interviews/Interviews.styled";
import { CreateInterviewPayload, CreateZoomMeetPayload, InterviewDurations, InterviewModeIconMap, InterviewModes, VideoInterviewTypes } from "Modules/Core/Interviews/InterviewsConstants";
import { ICreateInterviewPayload, ICreateZoomMeetPayload, IGeneratedTimeInterval, InterviewType, VideoInterviewType } from 'Modules/Core/Interviews/InterviewsModel';
import { useNotification } from 'Modules/Core/Notification';
import dayjs, { Dayjs } from 'dayjs';
import { IsSmScreen, IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ApiState } from 'shared/SharedModels';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn, ShTextField, ShToggleButtonGroup } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { resetRejectionEmail, resetZoomCreation, resetZoomDelete, resetZoomUpdate } from 'store/slices/employer/applicants/applicant-actions-slice';
import { changeApplicantStageInProfile } from 'store/slices/employer/applicants/applicant-profile-slice';
import { changeApplicantStageInList } from 'store/slices/employer/applicants/applicants-list-slice';
import { createInterviewSchedule, resetCreateInterview, resetUpdateInterviewSchedule, updateInterviewSchedule, updateInterviewCancelStatus } from 'store/slices/employer/interviews/interviews-actions-slice';
import { getInterviewDetails, resetGetInterviewDetails } from 'store/slices/employer/interviews/interviews-details-slice';
import { closeInterviewDialog } from 'store/slices/employer/interviews/interviews-shared-slice';
import { generateTimeIntervals } from './InterviewsUtilities';
import { ZoomMeet } from './ZoomMeet';
import { getTimezones } from 'store/slices/common/timezone-slice';
import AddIcon from '@mui/icons-material/Add';
import AddAttendeesDialog from './AddAttendeesDialog';

export const InterviewDialog = () => {

    const isSmScreen = IsSmScreen();
    const isXsScreen = IsXsScreen();
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const { zoomMeetCreationStatus, zoomMeetCreationResponse, zoomMeetResponse, zoomMeetUpdateResponse,
        zoomMeetUpdateStatus, zoomMeetDeleteStatus, zoomMeetDeleteResponse
    } = useAppSelector((state) => state.employer.applicants.applicantActions);
    const { createInterviewScheduleStatus, createInterviewScheduleResponse, updateInterviewScheduleStatus,
        updateInterviewScheduleResponse
    } = useAppSelector((state) => state.employer.interviews.interviewsActions);
    const { interviewDetails, getInterviewDetailsStatus } = useAppSelector((state) => state.employer.interviews.interviewsDetails);
    const { applicantInfo, candidateName, interviewId, isInterviewDialogOpen,
        jobId, candidateEmployerJobId, stage, usedIn } = useAppSelector((state) => state.employer.interviews.interviewsShared);
    const { timezones } = useAppSelector((state) => state.common.timezones);
    const zoomMeetRef = useRef<any>(null);
    const [_interviewDetails, _setInterviewDetails] = useState<ICreateInterviewPayload>({ ...CreateInterviewPayload });
    const [zoomMeet, setZoomMeet] = useState<ICreateZoomMeetPayload>({ ...CreateZoomMeetPayload });
    const [videoInterviewType, setVideoInterviewType] = useState<VideoInterviewType | ''>('');
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [timeInterval, setTimeInterval] = useState<number | 'custom'>(30);
    const [customInterval, setCustomInterval] = useState<number | string>(0);
    const [selectedTimeInterval, setSelectedTimeInterval] = useState<string>('');
    const [isAttendeeDialogOpened, setIsAttendeeDialogOpened] = useState(false);

    // Retrieve interview details callback.
    const fetchInterviewDetails = useCallback(() => {
        if (interviewId) {
            dispatch(getInterviewDetails({ id: interviewId }));
        }
    }, [dispatch, interviewId]);

    useEffect(() => {
      dispatch(getTimezones());
    }, [dispatch]);

    const _closeInterviewDialog = useCallback(() => {
        /*
        * Reset and clear every state and variable before closing dialog,
        *   to avoid any unexpected behavior. 
        * Since the model instance is not complete removed.
        */
        setZoomMeet({ ...CreateZoomMeetPayload });
        _setInterviewDetails({ ...CreateInterviewPayload });
        setVideoInterviewType('');
        setCustomInterval(0);
        setTimeInterval(30);
        setSelectedTimeInterval('');
        setIsPreviewDialogOpen(false);
        setIsSubmitDisabled(false);
        dispatch(closeInterviewDialog());
        dispatch(resetCreateInterview());
        dispatch(resetGetInterviewDetails());
        dispatch(resetUpdateInterviewSchedule());
        dispatch(resetZoomCreation());
        dispatch(resetZoomUpdate());
        dispatch(resetZoomDelete());
    }, [dispatch]);

    const onInterviewModeChange = (interviewType: InterviewType) => {
        // Reset 'zoomMeet' payload and 'videoInterviewType' if interview type is not video.
        if (interviewType !== 'Video') {
            setZoomMeet({
                agenda: '',
                from_time: '',
                password: '',
                schedule_date: '',
                topic: ''
            });
            setVideoInterviewType('');
        }
        _setInterviewDetails({ ..._interviewDetails, type_of_interview: interviewType, additional_interview_info: '' });
    };

    // Load interview details if interview id is given.
    useEffect(() => {
        if (isInterviewDialogOpen) {
            fetchInterviewDetails();
        }
    }, [dispatch, fetchInterviewDetails, isInterviewDialogOpen]);

    // Load interview details if interview is edited.
    useEffect(() => {
        if (zoomMeetUpdateStatus === 'success' || zoomMeetDeleteStatus === 'success') {
            fetchInterviewDetails();
        }
    }, [fetchInterviewDetails, zoomMeetDeleteStatus, zoomMeetUpdateStatus]);

    useEffect(() => {
        if (getInterviewDetailsStatus === 'pending') {
            dispatch(resetZoomCreation());
            dispatch(resetZoomUpdate());
            dispatch(resetZoomDelete());
            dispatch(resetRejectionEmail());
        }
    }, [dispatch, getInterviewDetailsStatus]);

    // Populate interview details on edit mode.
    useEffect(() => {
        if (getInterviewDetailsStatus === 'success' && interviewDetails) {
            const { from_time, additional_interview_info, note, schedule_date, to_time, type_of_interview,
                zoom_meeting_id, zoom_meeting, timezone, additional_attendees_email } = interviewDetails;
            _setInterviewDetails({
                from_time: from_time,
                additional_interview_info: additional_interview_info,
                note: note,
                schedule_date: schedule_date,
                to_time: to_time,
                type_of_interview: type_of_interview,
                zoom_meeting_id: zoom_meeting_id,
                timezone,
                additional_attendees_email: additional_attendees_email ?? []
            });

            if (type_of_interview === 'Video') {
                if (zoom_meeting_id && zoom_meeting?.response_data) {
                    setVideoInterviewType('zoom');
                    const { agenda, start_time, password, topic } = zoom_meeting?.response_data;

                    // retrieve date and time from 'start_time' since its in ISO format.
                    const dateTime = new Date(start_time);
                    const date = dateTime.toDateString();
                    const time = dateTime.toLocaleTimeString();

                    setZoomMeet({
                        agenda: agenda,
                        from_time: time,
                        password: password,
                        schedule_date: date,
                        topic: topic,
                        zoom_meeting_id: zoom_meeting_id
                    });
                } else {
                    setVideoInterviewType('other');
                }
            }

            // Calculate time interval with from and to times.
            if (from_time && to_time) {
                // Convert the times to Date objects
                const fromTimeDate = new Date(`1970-01-01T${from_time}:00`);
                const toTimeDate = new Date(`1970-01-01T${to_time}:00`);

                // Calculate the difference in milliseconds
                const timeDiff: number = toTimeDate.getTime() - fromTimeDate.getTime();

                // Convert the difference to minutes
                const _timeIntervalInMins = timeDiff / (1000 * 60);
                if (InterviewDurations.map(d => d.value).includes(_timeIntervalInMins)) {
                    setTimeInterval(_timeIntervalInMins);
                } else {
                    setTimeInterval('custom');
                    setCustomInterval(_timeIntervalInMins);
                }
                const _timeInterval = generateTimeIntervals(_timeIntervalInMins).find(tI => tI.startTime === from_time && tI.endTime === to_time)?.timeInterval;
                if (_timeInterval) {
                    setSelectedTimeInterval(_timeInterval);
                }
            }
        }
    }, [getInterviewDetailsStatus, interviewDetails]);

    // Disable submit button.
    useEffect(() => {
        const { additional_interview_info, schedule_date, type_of_interview, timezone } = _interviewDetails;
        setIsSubmitDisabled((videoInterviewType !== 'zoom' && !additional_interview_info) ||
            (videoInterviewType === 'zoom' && (!zoomMeet.agenda || !zoomMeet.password)) ||
            !schedule_date || !type_of_interview || !timezone);
    }, [_interviewDetails, videoInterviewType, zoomMeet.agenda, zoomMeet.password, zoomMeet.zoom_meeting_id]);

    const scheduleInterview = useCallback((interviewDetails: any) => {
        // Update interview payload with zoom details, if any. 
        const interviewPayload = { ...interviewDetails };
        interviewPayload.application_id = applicantInfo?.candidateEmployerJobId;
        dispatch(createInterviewSchedule(interviewPayload));
    }, [applicantInfo?.candidateEmployerJobId, dispatch]);

    // Update zoom info into _interviewDetails once its created.
    useEffect(() => {
        if (zoomMeetCreationStatus === 'success') {
            let newInterviewDetails = { ..._interviewDetails };
            newInterviewDetails.zoom_meeting_id = zoomMeetResponse?.id;
            newInterviewDetails.additional_interview_info = zoomMeetResponse?.response?.agenda ?? '';
            scheduleInterview(newInterviewDetails);
        }
    }, [_interviewDetails, scheduleInterview, zoomMeetCreationStatus, zoomMeetResponse?.id, zoomMeetResponse?.response?.agenda])

    const onScheduleInterviewClick = () => {
        if (videoInterviewType === 'zoom' && _interviewDetails.type_of_interview === 'Video') {
            zoomMeetRef.current?.handleZoomMeet('create');
        } else {
            scheduleInterview(_interviewDetails);
        }
    };

    const updateSchedule = () => {
        if (interviewDetails) {
            const { from_time, to_time, schedule_date, type_of_interview, additional_interview_info, note, zoom_meeting_id, timezone, additional_attendees_email } = _interviewDetails;
            dispatch(updateInterviewSchedule({
                id: interviewDetails.id,
                body: { from_time, to_time, schedule_date, type_of_interview, additional_interview_info, note, zoom_meeting_id, timezone, additional_attendees_email }
            }));
        }
    };

    // Show snackbar notifications when interview schedule is successfully created.
    useEffect(() => {
        if (createInterviewScheduleStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: createInterviewScheduleResponse
            });
            // Change stage after interview is scheduled.
            if (candidateEmployerJobId && stage) {
                if (usedIn === 'profile') {
                    dispatch(changeApplicantStageInProfile({ applicantId: candidateEmployerJobId, payload: { stage: stage, sendEmail: true } }));
                } else if (usedIn === 'list') {
                    dispatch(changeApplicantStageInList({ applicantId: candidateEmployerJobId, payload: { stage: stage, sendEmail: true } }));
                }
            }
            _closeInterviewDialog();
        }
    }, [_closeInterviewDialog, candidateEmployerJobId, createInterviewScheduleResponse, createInterviewScheduleStatus,
        dispatch, notification, stage, usedIn]);

    // Show snackbar notifications when interview schedule is successfully updated.
    useEffect(() => {
        if (updateInterviewScheduleStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: updateInterviewScheduleResponse
            });
            if (interviewId) {
                dispatch(updateInterviewCancelStatus({ id: interviewId, body: { status: 'Scheduled', comments: 'Re scheduled' } }));
            }
            // Change stage after interview is scheduled.
            if (candidateEmployerJobId && stage) {
                if (usedIn === 'profile') {
                    dispatch(changeApplicantStageInProfile({ applicantId: candidateEmployerJobId, payload: { stage: stage, sendEmail: true } }));
                } else if (usedIn === 'list') {
                    dispatch(changeApplicantStageInList({ applicantId: candidateEmployerJobId, payload: { stage: stage, sendEmail: true } }));
                }
            }
            _closeInterviewDialog();
        }
    }, [_closeInterviewDialog, candidateEmployerJobId, dispatch, interviewId, notification, stage,
        updateInterviewScheduleResponse, updateInterviewScheduleStatus, usedIn]);

    const showAlerts = (apiState: ApiState, pendingText: string, responseText: string, callBack?:
        ActionCreatorWithoutPayload<"applicantActions/resetZoomCreation"> |
        ActionCreatorWithoutPayload<"applicantActions/resetZoomUpdate"> |
        ActionCreatorWithoutPayload<"applicantActions/resetZoomDelete"> | undefined) => {
        switch (apiState) {
            case 'pending':
                return (<ShAlert stickyAlert marginBottom={2} severity="info">{pendingText}</ShAlert>);
            case 'success':
                return (<ShAlert stickyAlert marginBottom={2} severity="success"
                    onClose={() => callBack ? dispatch(callBack()) : ''}>{responseText}</ShAlert>);
            case 'failed':
                return (<ShAlert stickyAlert marginBottom={2} severity="error">{responseText}</ShAlert>);
            default:
                return (<></>);
        }
    };

    const onTimeIntervalChange = (_timeInterval: number | 'custom') => {
        setTimeInterval(_timeInterval);
        setSelectedTimeInterval('');
        setCustomInterval('');
    }

    const onCustomIntervalChange = (_timeInterval: string) => {
        // Allow only numbers to be entered
        if (/^\d*$/.test(_timeInterval)) {
            setCustomInterval(_timeInterval);
        }
    }

    const getTimeIntervalsWithByCurrentInterval = (): IGeneratedTimeInterval[] => {
        return generateTimeIntervals(timeInterval && timeInterval !== 'custom' ? timeInterval : customInterval ? parseInt(customInterval.toString()) : 30)
    }

    return (<>
        <ShContainer maxWidth='sm'>
            <InterviewDialogStyled fullScreen={isSmScreen} open={isInterviewDialogOpen} onClick={e => e.stopPropagation()}
                onClose={() => _closeInterviewDialog()} aria-labelledby="title">
                <DialogTitle variant='h6' margin={1.5} marginTop={isSmScreen ? 2 : 1.5} id='title' fontWeight={600}>
                    {getInterviewDetailsStatus === 'pending' ? 'Loading...' :
                        interviewDetails ? `Interview ${interviewDetails.status} - ${candidateName}` :
                            <Box display="flex" alignItems="center">
                                <InsertInvitationOutlinedIcon />
                                &nbsp; Schedule Interview
                            </Box>

                    }
                    <IconButton className='close-btn' onClick={() => { _closeInterviewDialog() }}><ClearIcon /></IconButton>
                </DialogTitle>
                <DialogContent>
                    {showAlerts(zoomMeetCreationStatus, 'Creating Zoom Meet...', zoomMeetCreationResponse, resetZoomCreation)}
                    {showAlerts(zoomMeetUpdateStatus, 'Updating Zoom Meet...', zoomMeetUpdateResponse, resetZoomUpdate)}
                    {showAlerts(zoomMeetDeleteStatus, 'Deleting Zoom Meet...', zoomMeetDeleteResponse, resetZoomDelete)}
                    {showAlerts(createInterviewScheduleStatus, 'Creating Interview schedule...', createInterviewScheduleResponse)}
                    {showAlerts(updateInterviewScheduleStatus, 'Updating Interview schedule...', updateInterviewScheduleResponse)}
                    {getInterviewDetailsStatus === 'pending' ?
                        <Stack justifyContent='center' alignItems='center'>
                            <CircularProgress />
                        </Stack> : <>
                            <Stack>
                                <Typography variant='subtitle1' marginBottom={2}> Applicant : <Chip label={candidateName || applicantInfo?.fullName} /> </Typography>
                                <Stack direction='row' columnGap={1}>
                                    <Typography variant='subtitle1'> Select interview duration </Typography>
                                    {isSmScreen && <Typography variant='subtitle1'> (Minutes) </Typography>}
                                </Stack>
                                <Stack direction='row' columnGap={1} rowGap={2} flexWrap='wrap' marginBottom={3}>
                                    {
                                        isSmScreen ?
                                            <Select size='small'
                                                value={timeInterval}
                                                onChange={(e) => onTimeIntervalChange(e.target.value as number | 'custom')}
                                                aria-label="Duration"
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => selected}
                                            >
                                                {InterviewDurations.map(dur => (
                                                    <MenuItem color='grey' key={dur.value} value={dur.value} aria-label={`${dur.label}`}>
                                                        {dur.label}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem color='grey' value={'custom'} aria-label="Custom Duration">
                                                    Custom Duration
                                                </MenuItem>
                                            </Select> :
                                            <ShToggleButtonGroup color="primary" size="small" exclusive
                                                orientation={isXsScreen ? 'vertical' : 'horizontal'} value={timeInterval}
                                                onChange={(e, newValue) => onTimeIntervalChange(newValue)}
                                                aria-label="Duration">
                                                {InterviewDurations.map(dur => (
                                                    <ToggleButton key={dur.value} value={dur.value} aria-label={`${dur.label}`}>
                                                        {dur.label}
                                                    </ToggleButton>
                                                ))}
                                                <ToggleButton value={'custom'} aria-label="Custom Duration">
                                                    Custom Duration
                                                </ToggleButton>
                                            </ShToggleButtonGroup>
                                    }

                                    {timeInterval === 'custom' &&
                                        <ShTextField size='small' value={customInterval}
                                            onChange={(e) => onCustomIntervalChange(e.target.value)}
                                            label="Duration (Minutes)" placeholder='Enter Duration (Minutes)' />}
                                </Stack>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Typography variant='body1' fontWeight={600} marginBottom={1.5}>Interview date & time</Typography>
                                    <Stack direction='row' flexWrap='wrap' columnGap={3} rowGap={2} maxWidth={600} marginBottom={2}>
                                        <Box width={170}>
                                            <DatePicker label="Select Date" value={_interviewDetails.schedule_date ?
                                                dayjs(_interviewDetails.schedule_date) : null}
                                                onChange={(newValue: Dayjs | null) => {
                                                    _setInterviewDetails({ ..._interviewDetails, schedule_date: newValue ? newValue.format('YYYY-MM-DD') : '' });
                                                }} slotProps={{ textField: { size: 'small' } }}
                                                disablePast disabled={zoomMeetCreationStatus === 'pending'}
                                            />
                                        </Box>
                                        <Box minWidth={210}>
                                            <FormControl variant="outlined" size='small' fullWidth
                                                disabled={!customInterval && timeInterval === 'custom'}>
                                                <InputLabel>Select Interview time</InputLabel>
                                                <Select size='small'
                                                    value={selectedTimeInterval}
                                                    disabled={zoomMeetCreationStatus === 'pending' || (!customInterval && timeInterval === 'custom')}
                                                    onChange={(event) => {
                                                        const interval = event.target.value;
                                                        setSelectedTimeInterval(interval);
                                                        const { startTime, endTime } = getTimeIntervalsWithByCurrentInterval()
                                                            .find(tI => tI.timeInterval === interval) ?? {};
                                                        _interviewDetails.from_time = startTime ?? '';
                                                        _interviewDetails.to_time = endTime ?? '';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    label="Select Interview time"
                                                    renderValue={(selected) => selected}
                                                >
                                                    {getTimeIntervalsWithByCurrentInterval().map((interval, index) => (
                                                        <MenuItem color='grey' key={index} value={interval.timeInterval}>
                                                            {interval.timeInterval}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {(!customInterval && timeInterval === 'custom') &&
                                                    <FormHelperText>Enter custom duration to select time interval</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                        <Box minWidth={120}>
                                            <FormControl variant="outlined" size='small' fullWidth>
                                                <InputLabel>Timezone</InputLabel>
                                                <Select size='small'
                                                    value={_interviewDetails.timezone}
                                                    disabled={zoomMeetCreationStatus === 'pending'}
                                                    onChange={(event) => {
                                                        const timezone = event.target.value;
                                                        _setInterviewDetails((prevInterviewDetails)=>({...prevInterviewDetails, timezone}))
                                                    }}
                                                    MenuProps={MenuProps}
                                                    label="Timezone"
                                                    renderValue={(selected) => selected}
                                                >
                                                    {timezones.map((timezone, index) => (
                                                        <MenuItem color='grey' key={index} value={timezone.abbr}>
                                                            {timezone.abbr}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Stack>
                                </LocalizationProvider>
                                <Box marginBottom={2}>
                                    <Typography variant='body1' fontWeight={600} gutterBottom>Interview Type</Typography>
                                    <ShToggleButtonGroup color="primary" size="small" exclusive variant='pill' buttonsGap={1.5}
                                        minWidth='100px' orientation={isXsScreen ? 'vertical' : 'horizontal'}
                                        value={_interviewDetails.type_of_interview}>
                                        {InterviewModes.map((interviewMode) => (
                                            <ToggleButton value={interviewMode} key={interviewMode} size='small'
                                                onClick={() => onInterviewModeChange(interviewMode)}>
                                                <Stack direction='row' columnGap={1} alignItems='center'>
                                                    {InterviewModeIconMap[interviewMode]}
                                                    <Typography variant='body2'>
                                                        {interviewMode}
                                                    </Typography>
                                                </Stack>
                                            </ToggleButton>
                                        ))}
                                    </ShToggleButtonGroup>
                                    {(_interviewDetails.type_of_interview === 'Video') &&
                                        <Typography variant='body1' fontWeight={600} marginY={2}>Select meeting software</Typography>}
                                    {_interviewDetails.type_of_interview === 'Video' &&
                                        <FormControl fullWidth disabled={zoomMeetCreationStatus === 'pending'} size='small'>
                                            <InputLabel id="duration-select-label">Meeting software</InputLabel>
                                            <Select label="Meeting software" value={videoInterviewType} id='i_t' size='small'
                                                onChange={e => {
                                                    setVideoInterviewType(e.target.value as VideoInterviewType);
                                                    _setInterviewDetails({ ..._interviewDetails, additional_interview_info: '' })
                                                }}>
                                                {VideoInterviewTypes.map(vIT => (
                                                    <MenuItem key={vIT.value} value={vIT.value}>{vIT.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>}
                                </Box>
                                {(_interviewDetails.type_of_interview === 'Video' && videoInterviewType !== 'zoom') &&
                                    <Typography variant='body1' fontWeight={600} marginBottom={2}>Share video call link</Typography>}
                                {_interviewDetails.type_of_interview === 'In-person' &&
                                    <Typography variant='body1' fontWeight={600} marginBottom={2}> Enter the Location Address</Typography>}
                                {_interviewDetails.type_of_interview === 'Phone' &&
                                    <Typography variant='body1' fontWeight={600} marginBottom={2}>Enter the Phone number</Typography>}

                                {_interviewDetails.type_of_interview && (applicantInfo || interviewDetails) && jobId && <>
                                    {videoInterviewType === 'zoom' && _interviewDetails.type_of_interview === 'Video' ?
                                        <ZoomMeet applicantInfo={applicantInfo} interviewDetails={_interviewDetails}
                                            setZoomMeet={setZoomMeet} zoomMeet={zoomMeet} jobId={jobId}
                                            forwardedRef={zoomMeetRef} interviewInfo={interviewDetails} /> :

                                        <TextField size='small' id="i_location" label={_interviewDetails.type_of_interview === 'In-person' ? "Interview Location/Address" :
                                            _interviewDetails.type_of_interview === 'Video' ? 'Video Link' : 'Phone number of the applicant'} required
                                            variant="outlined" value={_interviewDetails.additional_interview_info}
                                            onChange={e => _setInterviewDetails({ ..._interviewDetails, additional_interview_info: e.target.value })} />
                                    }
                                </>}
                                <Typography variant='body1' fontWeight={600} marginY={2}>Additional Attendees</Typography>
                                <ShButton
                                    variant="outlined" 
                                    color='inherit' 
                                    disabled={isAttendeeDialogOpened} 
                                    startIcon={<AddIcon />} 
                                    fullWidth
                                    onClick={() => setIsAttendeeDialogOpened(true)}
                                >
                                    {_interviewDetails.additional_attendees_email.length === 0 ? "Add Additional Attendees" : "Change Additional Attendees"}
                                </ShButton>
                                <Stack marginTop={2} direction="row" columnGap={1} flexWrap="wrap" rowGap={1}>
                                    {_interviewDetails.additional_attendees_email.map((email, index) => (
                                        <Chip size="small" key={index} label={email} />
                                    ))}
                                </Stack>
                                <Typography variant='body1' fontWeight={600} marginY={2}>Additional Info</Typography>
                                <TextField minRows={7} id="i_notes" label="More details" variant="outlined" multiline
                                    value={_interviewDetails.note} disabled={zoomMeetCreationStatus === 'pending'}
                                    onChange={e => _setInterviewDetails({ ..._interviewDetails, note: e.target.value })} />
                               
                            </Stack>
                        </>}

                </DialogContent>
                <DialogActions>
                    {getInterviewDetailsStatus !== 'pending' && <>
                        {
                            interviewDetails ?
                                <ShGreenBtn variant="contained" disableElevation size="small" onClick={updateSchedule}
                                    disabled={isSubmitDisabled || createInterviewScheduleStatus === 'pending'}
                                    className='self-right'>Schedule and Email</ShGreenBtn> :
                                <>
                                    <Stack direction='row' justifyContent='space-between' width='100%'>
                                        <ShButton variant="contained" disableElevation size="small"
                                            onClick={() => setIsPreviewDialogOpen(true)}>Preview</ShButton>
                                        <ShGreenBtn variant="contained" disableElevation size="small"
                                            disabled={isSubmitDisabled || createInterviewScheduleStatus === 'pending'}
                                            onClick={onScheduleInterviewClick}>Schedule and Email</ShGreenBtn>
                                    </Stack>
                                </>
                        }
                    </>}
                </DialogActions>
            </InterviewDialogStyled>
            {/* Preview dialog */}
            {applicantInfo && jobId &&
                <InterviewPreviewDialog isDialogOpen={isPreviewDialogOpen} setIsDialogOpen={setIsPreviewDialogOpen}
                    applicantInfo={applicantInfo} interviewInfo={_interviewDetails} jobId={jobId} selectedTime={selectedTimeInterval} />
            }
            <AddAttendeesDialog
            isDialogOpen={isAttendeeDialogOpened}
            selectedAttendees={_interviewDetails.additional_attendees_email}
            setSelectedAttendees={(emails)=>_setInterviewDetails((prevInterview)=>({...prevInterview, additional_attendees_email: emails}))}
            onClose={setIsAttendeeDialogOpened}
          />
        </ShContainer>
    </>);
};
