import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DuoIcon from '@mui/icons-material/Duo';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import FlagIcon from '@mui/icons-material/Flag';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, CardActions, CardContent, IconButton, Stack, Tooltip, Typography, lighten } from "@mui/material";
import { InterviewCardStyled } from 'Modules/Core/Interviews/Interviews.styled';
import { IInterviewCard, InterviewStatus } from "Modules/Core/Interviews/InterviewsModel";
import UpdateInterviewCancelStatusDialog from 'Modules/Core/Interviews/UpdateInterviewCancelStatusDialog';
import UpdateInterviewCompleteStatusDialog from 'Modules/Core/Interviews/UpdateInterviewCompleteStatusDialog';
import { useAppDispatch } from 'helpers/hooks';
import moment from "moment";
import { useState } from 'react';
import { ShAvatar, ShChip } from 'shared/SharedStyles/ShDataDisplay';
import { MUIGrey, PrimaryWordpressThemeColor, ShBorderRadius, WhiteColor } from 'shared/SharedStyles/styleConstants';
import { getInitialsFromName } from 'shared/utils';
import { openInterviewDialog } from 'store/slices/employer/interviews/interviews-shared-slice';
import { stringToColor } from '../Dashboard/Dashboard.styled';

export const InterviewCard = ({ interview, usedInDashboard }: IInterviewCard) => {

    const dispatch = useAppDispatch();
    const [isInterviewStatusDialogOpen, setIsInterviewStatusDialogOpen] = useState<boolean>(false);
    const [currentUpdateStatus, setCurrentUpdateStatus] = useState<InterviewStatus>();
    const intervieweeInitials = getInitialsFromName(interview.candidate.account.first_name + " " + interview.candidate.account.last_name, true, true);
    const getAvatarBackgroundColor = stringToColor(intervieweeInitials);

    const getShAvatar = () => {
        return (
            <ShAvatar minWidth={40} height={40} backgroundColor={getAvatarBackgroundColor} >
                <Typography variant='body1'>
                    {intervieweeInitials}
                </Typography>
            </ShAvatar>
        );
    };

    const getInterviewTypeChip = (interviewsState: String) => {
        switch (interviewsState) {
            case 'In-person':
                return (
                    <ShChip
                        icon={<PersonIcon style={{ color: `${WhiteColor}` }} />}
                        size='small'
                        label="In-person"
                        textColor={WhiteColor}
                        bgColor={lighten(PrimaryWordpressThemeColor, 0.1)}
                    />
                );
            case 'Phone':
                return (
                    <ShChip
                        icon={<LocalPhoneIcon style={{ color: `${WhiteColor}` }} />}
                        size='small'
                        label="Phone"
                        textColor={WhiteColor}
                        bgColor={lighten(PrimaryWordpressThemeColor, 0.1)}
                    />
                );
            case 'Video':
                return (
                    <ShChip
                        icon={<DuoIcon style={{ color: `${WhiteColor}` }} />}
                        size='small'
                        label="Video"
                        textColor={WhiteColor}
                        bgColor={lighten(PrimaryWordpressThemeColor, 0.1)}
                    />
                );

        }
    };

    // convert from 24hr to 12hr
    const convertToFormattedTime = (militaryTime: string): string => {
        if (!militaryTime)
            return '';
        const timeArray = militaryTime?.split(':');
        const hours = parseInt(timeArray[0], 10);
        const minutes = parseInt(timeArray[1], 10);

        // Ensure hours and minutes are within valid ranges
        const formattedHours = hours >= 0 && hours <= 23 ? (hours % 12 || 12) : 12;
        const formattedMinutes = minutes >= 0 && minutes <= 59 ? (minutes < 10 ? `0${minutes}` : `${minutes}`) : '00';

        // Determine AM/PM
        const period = hours >= 12 ? 'PM' : 'AM';

        // Construct the final time string
        const finalTime: string = `${formattedHours}:${formattedMinutes} ${period}`;

        return finalTime;
    }

    const _openInterviewDialog = () => {
        dispatch(openInterviewDialog({
            isInterviewDialogOpen: true,
            applicantInfo: undefined,
            candidateName: `${interview?.candidate?.account?.first_name} ${interview?.candidate?.account?.last_name}`,
            interviewId: interview.id,
            jobId: interview.employerjob.id
        }));
    };

    return (<>
        <InterviewCardStyled variant={'outlined'} onlyBottomBorder={usedInDashboard}
            borderRadius={usedInDashboard ? '0' : ShBorderRadius}>
            <CardContent>
                <Stack rowGap={2}>
                    <Stack direction='row' columnGap={1} alignItems='center'>
                        {getShAvatar()}
                        <Stack justifyContent='space-between' direction='row'>
                            <Box>
                                <Typography variant='body2' >
                                    {interview.candidate.account.first_name} {interview.candidate.account.last_name}
                                </Typography>
                                <Typography variant='caption' color={MUIGrey}>
                                    {interview.employerjob.name}
                                </Typography>
                            </Box>
                            {interview.re_scheduled_count > 3 &&
                                <Tooltip title='Interview rescheduled more than 3 times' placement='bottom'>
                                    <FlagIcon color='warning' />
                                </Tooltip>}
                        </Stack>

                        <Stack direction='row' justifyContent='space-between' ml='auto'>
                            {getInterviewTypeChip(interview.type_of_interview)}
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle2' color={MUIGrey} display='flex' alignItems='center' columnGap={0.4}>
                            <EventIcon fontSize='small' />{interview.schedule_date}
                        </Typography>
                        <Typography variant='subtitle2' color={MUIGrey} display='flex' alignItems='center' columnGap={0.4}>
                            <ScheduleIcon fontSize='small' /> {convertToFormattedTime(interview.from_time)} - {convertToFormattedTime(interview.to_time)}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
            <CardActions>
                <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
                    <Typography variant='caption'>{interview.status === 'Scheduled' ? 'Created' : 'Last updated'} on:&nbsp;
                        {interview.status === 'Scheduled' ? moment(interview.created_at).format("MMM DD, YYYY") :
                            moment(interview.updated_at).format("MMM DD, YYYY")}</Typography>
                    <Stack direction='row' className='action-items'>
                        {interview.status !== 'Completed' &&
                            <IconButton size='small' color='info'
                                onClick={() => _openInterviewDialog()}>
                                <Tooltip title={interview.status === 'Cancelled' ? 'Re-schedule' : 'Edit'} placement='bottom'>
                                    <EditIcon fontSize='small' />
                                </Tooltip>
                            </IconButton>
                        }
                        {interview.status === 'Scheduled' && <>
                            <IconButton size='small' color='error'
                                onClick={() => { setIsInterviewStatusDialogOpen(true); setCurrentUpdateStatus('Cancelled') }}>
                                <Tooltip title='Cancel' placement='bottom'>
                                    <CancelIcon fontSize='small' />
                                </Tooltip>
                            </IconButton>
                            <IconButton size='small' color='success'
                                onClick={() => { setIsInterviewStatusDialogOpen(true); setCurrentUpdateStatus('Completed') }}>
                                <Tooltip title='Complete' placement='bottom'>
                                    <CheckCircleIcon fontSize='small' />
                                </Tooltip>
                            </IconButton>
                        </>
                        }
                    </Stack>
                </Stack>
            </CardActions>
        </InterviewCardStyled>
        {currentUpdateStatus ==='Cancelled' && isInterviewStatusDialogOpen &&
            <UpdateInterviewCancelStatusDialog candidateName={`${interview.candidate.account.first_name} ${interview.candidate.account.last_name}`}
                interviewId={interview.id} status={currentUpdateStatus} isDialogOpen={isInterviewStatusDialogOpen}
                setIsDialogOpen={setIsInterviewStatusDialogOpen} />}
      {currentUpdateStatus === 'Completed' && isInterviewStatusDialogOpen && (
        <UpdateInterviewCompleteStatusDialog
          candidateName={`${interview.candidate.account.first_name} ${interview.candidate.account.last_name}`}
          interviewId={interview.id}
          status={currentUpdateStatus}
          isDialogOpen={isInterviewStatusDialogOpen}
          setIsDialogOpen={setIsInterviewStatusDialogOpen}
        />
      )}
    </>);
};