import { Alert, CircularProgress } from "@mui/material";
import { ApplicantsFooter } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsFooter';
import { ApplicantsListView } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsListView';
import { ApplicantsTableView } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsTableView';
import { ApplicantsToolBar } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsToolBar';
import { ApplicantsSortCols, FilterType, IApplicant, IApplicantsApiParams, IApplicantsFilter, TInterviewStage } from 'Modules/Core/Applicants/ApplicantsModel';
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { ISortParamsBase, LayoutTypes, SortOrder } from 'shared/SharedModels';
import { ShBackdrop } from "shared/SharedStyles/ShFeedback";
import { ListWrapper } from "shared/SharedStyles/ShLayouts";
import { DefaultPageSize } from 'shared/constants';
import { updateArrayById } from "shared/utils";
import { TBreadcrumbNavigation } from "store/slices/app/app-model";
import { setBreadcrumbLabelRegistry, setBreadcrumbNavFrom } from "store/slices/app/breadcrumb-slice";
import { downloadApplicantHiringGuide } from "store/slices/employer/applicants/applicant-actions-slice";
import {
    changeApplicantStageInList, fetchApplicantSummary, fetchApplicantsList,
    resetApplicantsState,
    toggleApplicantSummary
} from 'store/slices/employer/applicants/applicants-list-slice';

export const ApplicantsList = () => {
    const { jobId } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isSmScreen = IsSmScreen();
    const { applicantsList, getApplicantsListStatus, paginationParams, applicantsSummary, stageChangeStatus,
        getApplicantsListResponse, isAtsPurchased } = useAppSelector((state) => state.employer.applicants.applicantsList);
    const [layoutType, setLayoutType] = useState<LayoutTypes>(LayoutTypes.Table);
    const [selectedFilters, setSelectedFilters] = useState<IApplicantsFilter>({ stages: [], fit: [], keywords: [], rating: [] });
    const [sortParams, setSortParams] = useState<ISortParamsBase<ApplicantsSortCols>>({ orderBy: 'created_at', order: 'desc' });
    const [selectedApplicants, setSelectedApplicants] = useState<number[]>([]);
    const [searchKey, setSearchKey] = useState<string>("");
    const { breadcrumbNavFrom } = useAppSelector((state) => state.app.breadcrumb);
    const currentPage = paginationParams.requestedPageNumber || 1;

    /*
        Set Layout Type to List in smaller screen.
        Layout toggler will be hidden in small screen to block user from going to Table view.
    */
    useEffect(() => {
        if (isSmScreen) {
            setLayoutType(LayoutTypes.List);
        }
    }, [isSmScreen]);

    // Get applicants to form payload and make the API call.
    const getApplicants = useCallback((pageNo = currentPage, pageSize?: number, filters?: IApplicantsFilter,
        sortParams?: ISortParamsBase<ApplicantsSortCols>, search?: string) => {
        const params: IApplicantsApiParams = {
            jobId: parseInt(jobId ?? ''),
            pgNo: search ? 1 : pageNo ?? 1,
            pgSize: pageSize ?? DefaultPageSize,
            sortCol: sortParams?.orderBy ?? 'created_at',
            sortDir: sortParams?.order ?? 'desc',
            search: search ?? '',
            stages: filters?.stages?.map(stg => stg.value).join(','),
            ratings: filters?.rating?.map(stg => stg.value).join(','),
            finalMatches: filters?.fit?.map(stg => stg.value).join(','),
            keywords: filters?.keywords?.map(stg => stg.value).join(','),
        };
        if (isNaN(parseInt(jobId ?? ''))) {
            // If the job id is not a number then navigate to wild card route.
            navigate('/404');
        } else {
            dispatch(fetchApplicantsList(params));
        }
    }, [dispatch, jobId, navigate, currentPage]);

    const applyFilters = useCallback((filters: IApplicantsFilter) => {
        setSelectedFilters(filters);
        /*
            Call get applicants function with page number and size with undefined.
            Sets page size and page number to default while making API call. 
        */
        getApplicants(undefined, undefined, filters, sortParams, searchKey);

        // Add filters into session storage.
        sessionStorage.setItem(jobId + '', JSON.stringify(filters));
    }, [getApplicants, jobId, searchKey, sortParams]);

    // Get applicants list on page load.
    useEffect(() => {
        const appliedFilters = sessionStorage.getItem(jobId + '');
        if (appliedFilters) {
            applyFilters(JSON.parse(appliedFilters));
        } else {
            getApplicants();
        }
        return () => {
            // Reset applicant state on component unmount.
            dispatch(resetApplicantsState());
        }
    }, [applyFilters, dispatch, getApplicants, jobId]);

    const removeFilter = (filterType: FilterType, val: string) => {
        const tmpStages = [...selectedFilters[filterType]];
        const indx = tmpStages.map(ts => ts.value).indexOf(val);
        tmpStages.splice(indx, 1);
        const temp = { ...selectedFilters, [filterType]: tmpStages }
        setSelectedFilters(temp);
        sessionStorage.setItem(jobId + '', JSON.stringify(temp));
        /*
            Call get applicants function with page number and size with undefined.
            Sets page size and page number to default while making API call. 
        */
        getApplicants(undefined, paginationParams.requestedPageSize, temp, sortParams, searchKey);
    };

    const sortList = (sortBy: ApplicantsSortCols, order: SortOrder) => {
        setSortParams({ order: order, orderBy: sortBy });
        /*
           Call get applicants function with page number and size from pagination params.
       */
        getApplicants(paginationParams.requestedPageNumber,
            paginationParams.requestedPageSize,
            selectedFilters,
            { order: order, orderBy: sortBy },
            searchKey);
    };

    /*
        Call get applicant summary only if the applicant summary isn't already loaded.
        If applicant summary is already loaded, call 'toggleApplicantSummary' reducer in applicants slice to toggle expand/collapse.
    */
    const getApplicantSummary = (_candidateEmployerJobId: number) => {
        if (_candidateEmployerJobId in applicantsSummary && applicantsSummary[_candidateEmployerJobId].baseballCardUrl) {
            dispatch(toggleApplicantSummary(applicantsList.findIndex(al => al.candidateEmployerJobId === _candidateEmployerJobId)));
        } else {
            dispatch(fetchApplicantSummary({
                candidateEmployerJobId: _candidateEmployerJobId,
                jobId: parseInt(jobId ?? '')
            }));
        }
    };

    const searchInApplicants = (searchKey: string) => {
        getApplicants(paginationParams.requestedPageNumber, paginationParams.requestedPageSize, selectedFilters, sortParams, searchKey);
        setSearchKey(searchKey);
    };

    const changeStage = (stage: Lowercase<TInterviewStage>, candidateEmployerJobId: number, sendEmail: boolean) => {
        dispatch(changeApplicantStageInList({ applicantId: candidateEmployerJobId, payload: { stage: stage, sendEmail: sendEmail } }));
    };

    useEffect(() => {
        if (stageChangeStatus === 'success') {
            const appliedFilters = sessionStorage.getItem(jobId + '');
            if (appliedFilters) {
                applyFilters(JSON.parse(appliedFilters));
            }
        }
    }, [applyFilters, jobId, stageChangeStatus]);

    const goToProfile = (applicant: IApplicant) => {
        navigate(`/employer/job/${jobId}/applicant/${applicant.candidateEmployerJobId}/details`, { state: applicant });
        if (breadcrumbNavFrom) {
            let breadcrumbNav: TBreadcrumbNavigation = 'active_jobs';
            switch (breadcrumbNavFrom) {
                case 'active_jobs':
                    breadcrumbNav = 'active_jobs_applicants';
                    break;
                case 'expired_jobs':
                    breadcrumbNav = 'expired_jobs_applicants';
                    break;
                default:
                    break;
            }
            dispatch(setBreadcrumbNavFrom({ breadcrumbNav: breadcrumbNav }));
            dispatch(setBreadcrumbLabelRegistry({ labelReg: { [applicant.candidateEmployerJobId]: `${applicant.fullName}` } }));
        }
    };

    const Alerts = () => {
        switch (getApplicantsListStatus) {
            case 'pending':
                return <ShBackdrop open={true}><CircularProgress color="inherit" /></ShBackdrop>;
            case 'failed':
                return <Alert severity="error">{getApplicantsListResponse}</Alert>;
            default:
                <></>;
        }
    };

    const downloadHiringGuide = (applicant: IApplicant) => {
        dispatch(downloadApplicantHiringGuide({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }

    return (<>
        <ListWrapper>
            {/* ApplicantsToolBar component holds the template with Search, Filter, Sort and Layout toggle elements. */}
            <ApplicantsToolBar isSmScreen={isSmScreen} layoutType={layoutType} setLayoutType={setLayoutType}
                applyFilters={applyFilters} selectedFilters={selectedFilters} searchKey={searchKey}
                sortList={sortList} sortParams={sortParams} removeFilter={removeFilter} searchInApplicants={searchInApplicants} />
            {/* Show loading screen while list is being fetched. */}
            {Alerts()}
            {/* Switch between layout types. */}
            {applicantsList?.length > 0 ? (
                layoutType === LayoutTypes.Table ?
                    <ApplicantsTableView applicantsList={applicantsList} applicantsSummary={applicantsSummary}
                        getApplicantSummary={getApplicantSummary} goToApplicantProfile={goToProfile} isAtsPurchased={isAtsPurchased}
                        selectApplicant={(applicantId) => setSelectedApplicants(updateArrayById<number, number>(applicantId, selectedApplicants))}
                        selectedApplicants={selectedApplicants} sortParams={sortParams} sortList={sortList}
                        changeStage={changeStage} downloadHiringGuide={downloadHiringGuide} /> :
                    <ApplicantsListView applicantsList={applicantsList} applicantsSummary={applicantsSummary}
                        getApplicantSummary={getApplicantSummary} goToApplicantProfile={goToProfile} isAtsPurchased={isAtsPurchased}
                        selectApplicant={(applicantId) => setSelectedApplicants(updateArrayById<number, number>(applicantId, selectedApplicants))}
                        selectedApplicants={selectedApplicants} changeStage={changeStage} downloadHiringGuide={downloadHiringGuide} />
            ) : (
                getApplicantsListStatus === 'success' && <Alert severity="info">No Applicants Yet</Alert>
            )}
            {applicantsList?.length > 0 && paginationParams && paginationParams.totalPages > 0 && (
                <ApplicantsFooter isSmScreen={isSmScreen} paginationParams={paginationParams} selectedApplicants={selectedApplicants}
                    getApplicants={(pgNo, pgSize) => getApplicants(pgNo, pgSize, selectedFilters, sortParams, searchKey)}
                    jobId={parseInt(jobId ?? '')} />
            )}
        </ListWrapper>
    </>);
};