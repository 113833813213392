import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IInterview, IInterviewsListParams, IInterviewsListState } from 'Modules/Core/Interviews/InterviewsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultPageSize } from 'shared/constants';

const initialInterviewsListState: IInterviewsListState = {
    getInterviewsStatus: 'idle',
    interviews: [],
    paginationParams: {
        count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0
    }
};

export const getInterviewsList = createAsyncThunk<IBaseResponse<IInterview[]>, IInterviewsListParams, { rejectValue: IBaseResponse }>(
    "getInterviewsList",
    async ({ pageNo, pageSize, status, sort, sortBy }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.INTERVIEWS}/?status=${status}&pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}&sortBy=${sortBy}`)
            .then((response: AxiosResponse<IBaseResponse<IInterview[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const interviewsListSlice = createSlice({
    name: 'interviewsList',
    initialState: initialInterviewsListState,
    reducers: {
        resetInterviewsList: (state) => {
            state = { ...initialInterviewsListState };
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialInterviewsListState;
        });
        // get scheduled interviews
        builder.addCase(getInterviewsList.pending, (state) => {
            state.getInterviewsStatus = 'pending'
        });
        builder.addCase(getInterviewsList.fulfilled, (state, action) => {
            state.getInterviewsStatus = 'success';
            state.interviews = action?.payload?.data;
            state.paginationParams = action?.payload.stats;
        });
        builder.addCase(getInterviewsList.rejected, (state, action) => {
            state.getInterviewsStatus = 'failed';
            state.getInterviewsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.paginationParams = { count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0 };
        });
    }
});

export const { resetInterviewsList } = interviewsListSlice.actions;
export default interviewsListSlice;
