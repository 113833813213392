import { IApplicant, IZoomMeetResponseData, TInterviewStage } from "Modules/Core/Applicants/ApplicantsModel";
import { Dispatch, SetStateAction } from "react";
import { ApiState, IPaginationParams, ISortBase, ISortParamsBase, SortOrder } from "shared/SharedModels";
import { IAllJobDetails } from "store/slices/employer/jobs/jobs-slice-model";

export type InterviewsType = 'scheduled' | 'cancelled' | 'completed';
export type InterviewType = 'In-person' | 'Video' | 'Phone';
export type TInterviewDialogUsedIn = 'profile' | 'list';
export type VideoInterviewType = 'other' | 'zoom';
export enum InterviewTypes {
    Scheduled = 'scheduled',
    Cancelled = 'cancelled',
    Completed = 'completed'
};
export type InterviewSortCols = 'alphabetical' | 'recent';

export type InterviewStatus = 'Scheduled' | 'Cancelled' | 'Completed';

export type InterviewResult = 'pass' | 'fail'

export interface IInterviewsDashboard {
    gridProps?: {
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
    };
    usedInDashboard?: boolean
}

export interface IInterviewsListParams {
    status: 'Scheduled' | 'Completed' | 'Cancelled',
    pageNo: number,
    pageSize: number,
    sort: SortOrder,
    sortBy: 'alphabetical' | 'recent'
};

export interface IInterviewDetails {
    id: number,
    employer_id: number,
    employerjob_id: number,
    candidate_id: number,
    account_id: number,
    schedule_date: string,
    from_time: string,
    to_time: string,
    type_of_interview: InterviewType,
    additional_interview_info: string,
    note: string,
    status: InterviewStatus,
    comments: string,
    re_scheduled_count: number,
    zoom_meeting_id: number,
    zoom_meeting?: {
        id: number,
        response_data: IZoomMeetResponseData
    },
    created_at: string,
    updated_at: string,
    timezone: string,
    additional_attendees_email: string[]
};

export interface IInterview extends IInterviewDetails {
    account: {
        id: number,
        first_name: string,
        middle_name: string,
        last_name: string,
        email: string,
    },
    candidate: {
        id: number,
        account: IInterviewCandidate
    },
    employerjob: { id: number, name: string, }
};

export interface IInterviewCard {
    interview: IInterview,
    usedInDashboard?: boolean
};

export interface IInterviewCandidate {
    email: string,
    first_name: string,
    id: number,
    last_name: string,
    middle_name?: string
};

export interface IInterviewsListState {
    getInterviewsStatus: ApiState,
    getInterviewsResponse?: string,
    interviews: IInterview[],
    paginationParams: IPaginationParams
};

export interface IInterviewsSharedState {
    isInterviewDialogOpen: boolean,
    applicantInfo?: IApplicant,
    jobId?: number,
    interviewId?: number,
    candidateName?: string,
    stage?: Lowercase<TInterviewStage>,
    candidateEmployerJobId?: number,
    usedIn?: TInterviewDialogUsedIn
};

export interface IInterviewsActionsState {
    createInterviewScheduleStatus: ApiState,
    createInterviewScheduleResponse: string,
    updateInterviewStateStatus: ApiState,
    updateInterviewStateResponse: string,
    updateInterviewScheduleStatus: ApiState,
    updateInterviewScheduleResponse: string,
    getJobDetailsForInterviewStatus: ApiState,
    getJobDetailsResponse: string,
    jobDetails?: IAllJobDetails
};

export interface ICreateInterviewPayload {
    application_id?: number,
    type_of_interview: InterviewType | '',
    schedule_date: string,
    from_time: string,
    to_time: string,
    additional_interview_info: string,
    note: string,
    zoom_meeting_id?: number,
    timezone: string,
    additional_attendees_email: string[]
};

export interface ICreateZoomMeetPayload {
    agenda: string,
    topic: string,
    password: string,
    schedule_date: string,
    zoom_meeting_id?: number,
    from_time: string,
};

export interface IUpdateInterviewCancelStatusParams {
    id: number,
    body: {
        comments: string,
        status: InterviewStatus
    }
};

export interface IUpdateInterviewCompleteStatusParams {
    id: number,
    body: {
        feedback: string,
        status: InterviewStatus,
        completedDateTime: string,
        // interviewResult: InterviewResult,
        shareTo: number[],
        rating: string
    }
};

export interface IUpdateInterviewScheduleParams {
    id: number,
    body: {
        from_time: string,
        to_time: string,
        schedule_date: string,
        type_of_interview: string,
        additional_interview_info: string,
        note: string,
        zoom_meeting_id?: number,
        timezone: string
        additional_attendees_email: string[]
    }
};

export interface IInterviewsSummary {
    scheduled: number,
    cancelled: number,
    completed: number,
};

export interface IInterviewsDetailsState {
    getInterviewsSummaryStatus: ApiState,
    getInterviewsSummaryResponse?: string,
    getInterviewDetailsStatus?: ApiState,
    getInterviewDetailsResponse?: string,
    interviewsSummary?: IInterviewsSummary,
    interviewDetails?: IInterviewDetails
};

export interface IInterviewsToolbar extends ISortBase<InterviewSortCols> {
    interviewsType: InterviewsType,
    setInterviewsType: Dispatch<SetStateAction<InterviewsType>>,
    interviewsCount: IInterviewsSummary,
    getInterviews: (interviewsState: InterviewsType, pageNo?: number, pageSize?: number,
        sortParams?: ISortParamsBase<InterviewSortCols>) => void,
};

export interface IInterviewsFooter {
    paginationParams: IPaginationParams,
    interviewsType: InterviewsType,
    getInterviews: (interviewsState: InterviewsType, pageNo?: number, pageSize?: number,
        sortParams?: ISortParamsBase<InterviewSortCols>) => void,
    isSmScreen: boolean,
};

export interface IUpdateInterviewStateDialog {
    isDialogOpen: boolean,
    setIsDialogOpen: Dispatch<SetStateAction<boolean>>,
    status: InterviewStatus,
    interviewId: number,
    candidateName: string
};

export interface AddAttendeesDialogProps {
    selectedAttendees: string[];
    isDialogOpen: boolean;
    setSelectedAttendees: (emails: string[])=>void;
    onClose: (value: boolean)=>void;
}

export interface IInterviewPreviewDialog {
    isDialogOpen: boolean,
    setIsDialogOpen: Dispatch<SetStateAction<boolean>>,
    applicantInfo: IApplicant,
    interviewInfo: ICreateInterviewPayload,
    jobId: number,
    selectedTime: string
};

export interface IZoomMeetComponent {
    zoomMeet: ICreateZoomMeetPayload,
    setZoomMeet: React.Dispatch<React.SetStateAction<ICreateZoomMeetPayload>>,
    interviewDetails: ICreateInterviewPayload,
    applicantInfo?: IApplicant,
    interviewInfo?: IInterviewDetails
    jobId: number,
    forwardedRef?: any,
};
// export interface IScheduleInterviewDialog {
//     isDialogOpen: boolean,
//     setIsDialogOpen: Dispatch<SetStateAction<boolean>>,
//     applicantInfo?: IApplicant,
//     jobId: number,
//     interviewId?: number,
//     candidateName?: string
// };

export interface IGeneratedTimeInterval {
    timeInterval: string;
    startTime: string;
    endTime: string;
}